<template>
  <nav>
    <!-- <img class="logo" src="https://www.dataeffects.com.au/assets/img/portfolio/Home_Snails.jpg" alt="Logo" /> -->

   
    <b-list-group :key="userDetailsKey" class="user-global-details" >
      <b-list-group-item class="d-flex align-items-center">
        <b-avatar v-if="isLoggedIn()" :variant="primary" style="margin-right:20px;">
          {{userInfo && userInfo['userName']? userInfo['userName'][0]:''}}
        </b-avatar> 
   
        <b v-if="isLoggedIn()">{{userInfo && userInfo['userName']? userInfo['userName']:''}}</b>
       
        <b-button variant="link" v-b-modal.login-input>{{isLoggedIn()? 'Switch Account':'Login'}}</b-button>
      </b-list-group-item>
    </b-list-group>           
    
  

    <b-modal ref='login-input' id="login-input" title="Login" hide-footer>
      <b-alert v-model="loginFailed" variant="danger" dismissible>
        Login Failed!
      </b-alert>
      <form @submit.prevent="login" class="form-inline">
        <div class="form-group">
          <label for="userName" class="sr-only">Username:</label>
          <input type="string" id="userName" class="form-control" v-model="userName" required>
          <label for="password" class="sr-only">Password:</label>
          <input type="password" id="password" class="form-control" v-model="password" required>
        </div>
        <button type="submit" class="btn btn-primary">Login</button>
      </form>
    </b-modal>
    
    <router-link to="/">Map</router-link> | 
    <router-link to="/snail-videos">Videos</router-link>  
    <!-- <router-link to="/dashboard">Dashboard</router-link>  |
    <router-link to="/leaderboard">Leaderboard</router-link> -->

    

   
  
  </nav>
  <router-view/>
</template>

<script>
import SnailService from "@/services/SnailService.ts";
const snailService = new SnailService();
export default {
  data(){
    return{
      userDetailsKey:0,
      userName: '',
      password: '',
      loginFailed: false,
      userInfo: null,
    }
  },
  methods:{
    updateUserDetails(){
      this.userDetailsKey++;
    },
    login(){
  
      snailService.login(this.userName, this.password)
        .then(res=>{
          if(res){
            
            // this.$refs['login-input'].hide();
            
            // this.getUserInfo();

          
            // refreshes the page
            location.reload();
          }else{
            this.loginFailed = true;
          }
          
        })
    },

    getUserInfo(){
      var id = snailService.getCurrentUserId();
      snailService.getUserInfo()
        .then(res => {
          this.userInfo=res;
          this.updateUserDetails();
         
        
        })
    },

    isLoggedIn(){
      return snailService.isLoggedIn();
    }


  },
  mounted(){
    if(this.isLoggedIn()){
      this.getUserInfo();
     
    }
  }
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 40px;
  //background-color: antiquewhite;
  border-bottom: #2c3e50 1px solid;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-active {
      color: #42b983;
    }
  }
}
.logo {
  position: absolute; /* Position the logo absolutely within the app-container */
  top: 0px; 
  left: 25px;
  width: 120px; 
  
  height: auto; 
  z-index: -1;
}
.user-global-details {
  position: absolute; /* Position the logo absolutely within the app-container */
  top: 25px; 
  left: 25px;
  max-width: 500px; 
  justify-content: space-between;
  height: auto; 
  z-index: 1;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
   
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

</style>
