<template>
     <div class="title-container">
            <!-- TODO: replace with own logo -->
            <b-img center width="200" src="https://www.shutterstock.com/image-vector/vector-design-form-crown-snail-260nw-2000862644.jpg" rounded="circle" alt="Circle image"></b-img>
            <!-- <h1>Snails Leaderboard</h1> -->
    </div>
 
    <div class="main-content">
        <div class="leaderboard-container">
            <h2>Top Players</h2>
            <b-list-group>
                <b-list-group-item v-for="user in topUsers" :key="user.id" :class="{ 'leaderboard-item': true, 'user-leaderboard-item': user.id == currentUserId }">
                
                <!-- TODO: click to go to user's dashboard? -->
                <div class="user-details">
                    <div class="user-rank">{{ user.rank }}</div>
                    <b-avatar :variant="getAvatarVariant(user.rank)" :text="user.userName[0]" class="align-baseline"></b-avatar>
                    <span class="user-name">{{ user.userName }}</span>
                    
                </div>
                <span class="user-score">Score: {{ user.score }}</span>
                </b-list-group-item>
            </b-list-group>
        </div>
        
        <div class="prizes-container">
            
            <h2>Prizes</h2>
            <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="500"
            
            style="
                text-shadow: 1px 1px 2px #333; 
                border-radius: 20px;
                overflow: hidden;"
                
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
            >
                <!-- Replace with actual prize -->
                <b-carousel-slide
                    caption="First prize"
                    text="[prize description]"
                    img-src="https://www.gagebeasleyshop.com/cdn/shop/products/product-image-1488937031.jpg?v=1596426830"
                    
                ></b-carousel-slide>
            
                <b-carousel-slide
                    caption="Second prize"
                    text="[prize description]"
                    img-src="https://i.etsystatic.com/9897152/r/il/8f36ed/4206136364/il_fullxfull.4206136364_kx0q.jpg"
                ></b-carousel-slide>

                
            </b-carousel>

    
        </div>

    </div>    
    
  </template>
  
  <script>
  import SnailService from "../services/SnailService";
  const snailService = new SnailService();
  
  export default {
        name: 'LeaderboardView',
        data() {
            return {
                currentUserId: 0,
                topUsers: [],
            };
        },
        created() {
            this.getTopUsers();
            this.getCurrentUserId();
        },
        methods: {
            getTopUsers() {
                snailService.getTopUsers(5).then(res => (this.topUsers = res));
            },

            getCurrentUserId(){
                this.currentUserId = snailService.getCurrentUserId();
            },
            getAvatarVariant(rank) {
                // Define logic to determine avatar variant (e.g., based on rank)
                if(rank === 1){
                    return 'warning';
                }
                else if (rank === 2){
                    return 'danger';
                }
                else if( rank === 3){
                    return 'success';
                }
        
                return  'secondary';
            },
            getRankColor(rank){
                return rank === 1 ? 'primary' : 'secondary';
            },
        },

  };
  </script>
  
<style scoped>
    .leaderboard-container {
        flex:50%;
        max-width: 1000px;
        margin: 0 auto;
        /* margin-top:20px; */
        padding: 20px;
        /* border-top: 1px solid #000000; */
        /* border-bottom: 1px solid #000000; */
        flex-wrap: wrap;
        justify-content: space-between;
        /* border-right: 1px solid #000000; */
    }
    
    .leaderboard-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        padding: 10px;
        border: 1px solid #f9f3ec;
        border-radius: 20px;
        background-color: #f9f3ec;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .leaderboard-item:hover {
        transform: translateY(-5px);
    }
    
    .user-leaderboard-item{
        /* border: 2px solid #000000; */
        background-color: #e2c59e;
    }
    
    .user-rank {
        font-weight: bold;
        font-size: 1.2rem;
        margin-right: 10px;
    }
    
    .user-details {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    
    .user-name {
        margin: 20px;
        font-size: 1.2rem;
        font-weight: bold;
    }
    
    .user-score {
        display: flex;
        flex-direction: row-reverse;
  
        font-size: 1rem;
        color: #888;
    }
    .prizes-container {
        /* width: 500px; */
        max-width:500px;
        flex:50%;
        padding: 40px;
        /* margin: 0 auto; */
        margin:50px;
        /* margin-right:100px; */
        margin-left:0px;
        border-radius: 20px;

        background-color: #f9f3ec;
        box-shadow: 0 2px 4px rgba(59, 61, 0, 0.595);
    }
    .title-container {
        width: 100vw;
        background-image: linear-gradient(to bottom right, #058373, #058373); 
        /* background-color: #058373; */
        margin: 0 auto;
        padding: 20px;
    }
    .main-content{
        display: flex;

    }

</style>
  