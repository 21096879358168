<template>
    <b-list-group-item button v-if="alert.level !== 0">
        <img :src="alertIcon" :alt="levelCategory" style="width:50px;height:50px;">
        <div @click="onViewLocation">
            {{readableTime }}:  <br>
            <b> Level: {{ alert.level.toFixed(2) }}</b>  | Confidence: {{ alert.confidence.toFixed(2) }}<br>
            
        </div>
            
    </b-list-group-item>
    <!--<a href="https://www.flaticon.com/free-icons/tick" title="tick icons">Tick icons created by kliwir art - Flaticon</a>-->
    <!--<a href="https://www.flaticon.com/free-icons/warning" title="warning icons">Warning icons created by Good Ware - Flaticon</a>-->
    <!--<a href="https://www.flaticon.com/free-icons/warning" title="warning icons">Warning icons created by Good Ware - Flaticon</a>-->
</template>

<script>

export default{

    props:{
        alert:{}
    },
    computed:{
        levelCategory(){
            if(this.alert.level > 4){
                return "high";
            }
            else if(this.alert.level > 1){
                return "medium";
            }
            else{
                return "low";
            }
        },
        alertIcon(){
            return require("@/assets/alert-" + this.levelCategory + ".png");
        },
        readableTime(){
            var dateOptions = {  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            var timestamp = new Date(this.alert.timestamp.replace(/\+11:00$/, "Z")); // alert timestamps are mislabelled. they should be in UTC. (TODO: fix in backend)
            return timestamp.toLocaleDateString("en-AU", dateOptions);
        }
    },
    methods:{
        onViewLocation(){
            console.log(this.alert.cameraDeployment)
            this.$emit('viewLocation',  this.alert.cameraDeployment.latitude, this.alert.cameraDeployment.longitude);
        }
    }
}

</script>
<style>
.alert-msg{
    display: grid;
    margin: 15px;
    grid-template-columns: 1fr 6fr ;
}

</style>