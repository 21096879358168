<template>
    
    <div class="dashboard">
        <div class="sidebar">
           
           <div class="card-body user-details">
               <b-avatar style="margin-bottom: 20px;" variant="info">
                   {{ userInfo? userInfo.userName[0] : ''}}
               </b-avatar>
           
               <h4>{{ userInfo? userInfo.userName : ''}}</h4>
               Rank: #{{ userInfo? userInfo.rank : '' }}
               <hr>
               <h5>Stats</h5>
               <b>Score:</b> {{ userInfo? userInfo.score : '' }} <br>
               <b>Answers:</b> {{ answerHistory.length }} <br>
               <b>Snails Found:</b> {{ 9999 }} <br>

           </div>
       </div>
        
        <div class="main-content">
            <b-card class="card wide-card">
                <h2 class="card-header">Score Distribution</h2>
                <div v-if="hovered" class="score-info">
                    <span class="top-users">
                        Top <b>{{ hoveredTopPercentage }} </b>
                    </span>
                         {{ hoveredCount }} Users - {{ hoveredPercentage }}
                    </div>

                <div v-else class="score-info">
                    Your score places you in the top <b>{{ userTopPercentage }}</b>. Well done!
                </div>

                
                <div class="card-body statistics-details" id="histogram">
                    
                </div>
            </b-card>
            <b-card class="card chart-card">
                <h2 class="card-header">Answers Summary</h2>
                <div class="card-body chart-container">
                <!-- Chart components go here -->
                </div>
            </b-card>

            <b-card class="card chart-card">
                <h2 class="card-header">Achievements</h2>
                <div class="card-body chart-container">
                <!-- Chart components go here -->
                </div>
            </b-card>
            <b-card class="card wide-card">
                <h2 class="card-header">My Answers</h2>
                <div class="card-body chart-container">
                    <b-list-group>
                        <b-list-group-item button v-for="answerEntry in answerHistory" :key="answerEntry.id">
                            <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">Question ID: {{ answerEntry.questionId }}</h5>
                            <small>Video ID: {{ answerEntry.videoId }}</small>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="mb-1">Answer: {{ answerEntry.answer }}</p>
                                <!-- <b-badge variant="primary" pill>14</b-badge> -->
                            </div>
                           
                        </b-list-group-item>
                    </b-list-group>     
                </div>
            </b-card>

        </div>
      
     
  
      
    </div>
  </template>
  
<script>
import Plotly from 'plotly.js-dist';
import SnailService from "@/services/SnailService.ts";
const snailService = new SnailService();

//import { AgChartsVue } from 'ag-charts-vue3';
export default {
        name: 'DashboardView',
        components: {
          
    },
    data() {
        return {
            answerHistory: [],
            userInfo: null,
            // test data : https://www.ag-grid.com/vue-charts/gallery/histogram-with-specified-bins/
            histogramData: [],
            hovered: false,
            hoveredBin : 0,
            hoveredCount: 0,
          
        };
        
    },
    computed:{
        totalCount(){
            if(this.histogramData && this.histogramData.counts){
                return this.histogramData.counts.reduce((a, b) => a + b, 0);
            }   
            return 0;
        },
        hoveredPercentage(){
            if(this.totalCount ==0 || !this.hovered){
                return '';
                
            }
            return Number(this.hoveredCount/this.totalCount)
            .toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
        },
        hoveredTopPercentage(){
            if(this.totalCount>0 && this.hovered){
                var topCount = 0;
                var index = this.histogramData.bins.length-1;
            
                while(this.hoveredBin <= this.histogramData.bins[index]){
                    topCount += this.histogramData.counts[index]
                    index--; 
                }
                return Number(topCount/this.totalCount)
                .toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
            }
            return '';

        },
        userTopPercentage(){
            if(this.totalCount>0){
                return Number(this.userInfo.rank/this.totalCount)
                .toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
            }
            return '';
        }
        // userScoreBin(){
        //     if(this.histogramData !== []){
        //         var index = 0;
                
                
        //         while(this.score > this.histogramData.bins[index] + this.histogramData.binWidth){
        //             index++; 
        //             console.log(this.histogramData.bins[index]+this.histogramData.binWidth)
        //         }
        //         //this.hoveredBin = this.histogramData.bins[index];
        //         this.drawHistogram();
        //         return this.histogramData.bins[index];
        //     }
        //     return -1;
        // }   
    },
    watch:{
       
    },
    methods:{
        getScoreBin(score){
            if(this.histogramData !== []){
                var index = this.histogramData.bins.length-1;
        
                while(score < this.histogramData.bins[index])index--; 

                if(index < 0){
                    index = 0;
                }
                return this.histogramData.bins[index];
            }
            return -1;

        },
        drawHistogram(){
            this.hoveredBin = this.getScoreBin(this.userInfo.score);
            var graphData = {
                x: this.histogramData.bins,
                y: this.histogramData.counts,
                text:this.histogramData.bins.map(d => d+ this.histogramData.binWidth),
                marker:{
                    color: this.histogramData.bins.map(d => d===this.hoveredBin ?'#047263':'lightgrey'),
                },
                offset: 0,
                width:this.histogramData.binWidth - 20, // leave a small gap
                //hoverinfo:`x - x+${this.histogramData.binWidth}`,
                type: 'bar',
                textfont: {
                    color: "transparent",
                },
                hovertemplate:`%{x}-%{text}<extra></extra>` 
            };
            console.log(this.histogramData.bins)
            console.log(graphData);
            const config = {
                displayModeBar: false, // this is the line that hides the bar.
            };
            var layout = {
                    title: '',
                    height:300,
                    //bargap:0.05,
                    margin: {
                        l: 0,
                        r: 50,
                        b: 20,
                        t: 20,
                        pad: 1
                    },
                    //hovermode: false,
                    xaxis: {
                        dividercolor: 'transparent',
                    },
                    yaxis: {
                        dividercolor: 'transparent',
                        color: 'transparent',
                        gridcolor: 'transparent',
                    },

                    legend: {
                        x: 1,
                        xanchor: 'right',
                        y: 1,
                        bgcolor: 'transparent'
                    },
         
          
                    paper_bgcolor:'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)'
                };
            
                
         
              
            Plotly.newPlot('histogram', [graphData], layout, config);
            var plotDiv = document.getElementsByClassName('point') //class name of plotly main area
            console.log(plotDiv);
                plotDiv[0].style.borderRadius = "15px"; //or however round you want
            var myPlot = document.getElementById('histogram');
            
            myPlot.on('plotly_hover', data =>{
                //console.log(data)
                this.hovered = true;
                this.hoveredCount = data.points[0].y;
        
                this.hoveredBin = data.points[0].x;
                var update = {marker:{
                    color: this.histogramData.bins.map(d => d===this.hoveredBin ?'#047263':'lightgrey'),
                }};
                Plotly.restyle('histogram', update)
            })
            .on('plotly_unhover', (data) =>{
                this.hovered = false;
                this.hoveredBin = this.getScoreBin(this.userInfo.score);
                var update = {marker:{
                    color: this.histogramData.bins.map(d => d===this.hoveredBin ?'#047263':'lightgrey'),
                }};
                Plotly.restyle('histogram', update)
            });
        },

        getUserInfo(){
        var id = snailService.getCurrentUserId();
        snailService.getUserInfo(id)
            .then(res => {
            this.userInfo=res;
            //this.updateUserDetails();
            })
            .finally(_ => {
                this.getHistogramData();
            })
        },
        getAnswerHistory(){
            snailService.getAnswerHistory()
                .then(res => this.answerHistory = res )
        },

        getHistogramData(){
            snailService.getScoresHistogram()
                .then(res => this.histogramData = res)
                .finally(_ => this.drawHistogram());
        },

        isLoggedIn(){
            return snailService.isLoggedIn();
        },
    },
    created(){
        if(this.isLoggedIn()){
            this.getUserInfo();
            this.getAnswerHistory();
            //this.getHistogramData();
            
        }
    }
};
</script>
  
  <style scoped>
  .sidebar {
    /* border: 1px solid #ccc; */
    border-radius: 20px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    margin: 0 0 20px;
    padding:10px;
    width: 300px;
    height: 1000px;
    background: #f9f3ec;
    /* box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.267);
}
  .dashboard {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
  }
  

  .main-content{
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    border: 1px solid #f9f3ec;
    border-radius: 20px;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    margin: 0 0 20px;
    background-color: #f9f3ec;
    flex-basis: calc(50% - 20px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.267);
    
  }
  
  .card-header {
    background-color: transparent;
    color: #047263;
    margin: 10px;
    padding: 10px;
 
    text-align: center;
    font-size: 16px;
    font-weight: bold;

  
  }
  
  .card-body {
    padding: 20px;

    text-align: left;
  }
  
  .wide-card {
    flex-basis: calc(100% );
    /* height: 300px; */
  }
  
  .chart-card {
    background-color: #f9f3ec;
    
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.267);
  }

  .score-info {
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;

 
  }

  .top-users {
    color: #007bff; 
    margin-right: 30px;
  }
  
  
  </style>
  