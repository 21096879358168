import axios from 'axios';

// const API_URL = process.env.VUE_APP_API_URL;
// not sure why not reading from .env
const API_URL = 'https://snail-api.dtfx.com.au';
const LOGIN_EXPIRATION = 3600000; // one hour
const config = {
    headers: { Authorization: `Bearer $` }
}
//const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJlYWQ0OTQ4Mi1hMjIxLTRhYTQtOTU1NS1iNGNmM2ZiZDhhYjIiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJhZG1pbiIsImV4cCI6MTcwOTExMTM1NCwiaXNzIjoiZGV2LWlzc3VlciIsImF1ZCI6ImRldi1hdWRpZW5jZSJ9.Noir7nhdORAooOkDP4Xlw-kpkDTTLWduyWvmTOI7UGY';
export default class SnailService{
   

    public getCurrentUserId(){
        if(this.isLoggedIn()){
            return localStorage.getItem('userId');
        }
        return null;
        
    }

    // signs in the user and stores the user id for api calls
    public async login(userName:string, password: string): Promise<boolean>{
        // TODO: handle sign in via external account system
      
        const details: any = {
            'userName': userName,
            'password': password 
            
        };
  
        try{
            const result = await axios.post(`${API_URL}/metadata/api/login`, details);

            localStorage.setItem('jwt', result.data);
            localStorage.setItem('userName', userName);
            localStorage.setItem('loginTime', String(Date.now()));
            
            return true;
        }
        catch(error){
            console.log(error);
        }
        
        return false;
    }
   

    public isLoggedIn(){
        //return false;
        if(localStorage.getItem('loginTime')===null || Date.now() - parseInt(localStorage.getItem('loginTime')!) > LOGIN_EXPIRATION ){
            
            // previously logged in
            if(localStorage.getItem('userName') !== null){
                localStorage.removeItem('userName');
                localStorage.removeItem('jwt');
                location.reload();
            }
            
            
            return false;
        }
    
        return true;
    }

    public async getCameras(){
        try{
            
            const result = await axios.get(`${API_URL}/metadata/api/all-cameras`,
                 {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getCurrentCameraDeployments(){
        try{
            //console.log(localStorage.getItem('jwt'));
            const result = await axios.get(`${API_URL}/metadata/api/current-camera-deployments`,
                 {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getCurrentMyriotaDeployments(){
        try{
            //console.log(localStorage.getItem('jwt'));
            const result = await axios.get(`${API_URL}/metadata/api/current-myriota-deployments`,
                 {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getAlertsFromCameraDeployment(id: number){
        try{
            const result = await axios.get(`${API_URL}/metadata/api/camera-deployment-alerts?id=${id}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getDevicesInArea(lat: number, lng: number, radius: number){
        try{
            const result = await axios.get(`${API_URL}/metadata/api/devices-in-area?lat=${lat}&lng=${lng}&radius=${radius}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }


    public async getDataTypes(){
        try{
            //console.log(localStorage.getItem('jwt'));
            const result = await axios.get(`${API_URL}/environmental/api/get-data-types`);
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    // to be deprecated. use getEnvironmentalData()
    public async getSensorData(sensorId: number, deploymentId: number){
        try{
          
            const result = await axios.get(`${API_URL}/metadata/api/sensor-data?deploymentId=${deploymentId}&sensorId=${sensorId}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getRecentEnvironmentalData(deviceId: string, hours:number, type:string){
        try{
           
            // assue only want data within the most recent hours
            const endTime = new Date();
            const startTime = new Date(endTime);

            startTime.setHours(startTime.getHours() - hours);
            const result = await axios.get(`${API_URL}/environmental/api/get-data?deviceId=${deviceId}&start=${startTime.toJSON()}&end=${endTime.toJSON()}&type=${type}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getEnvironmentalData(deviceId: string, start: Date | null, end: Date | null, type:string){
        try{
         
            // assue only want data within the most recent hours

            const params: { [key: string]: any } = {  };
            params.deviceId = deviceId
            params.type = type
      
            if (start !== null) {
                
                params.start = start.toJSON();
            }
          
            if (end !== null) {
                params.end = end.toJSON();
              
            }

           
            const result = await axios.get(`${API_URL}/environmental/api/get-data`,
            { params, headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    public async getMyriotaData(terminalId: string, start: Date | null, end: Date | null, type:string){
        try{
         
            // assue only want data within the most recent hours

            const params: { [key: string]: any } = {  };
            params.terminalId = terminalId
            params.type = type
      
            if (start !== null) {
                
                params.start = new Date(start).toJSON();
            }
          
            if (end !== null) {
                params.end =  new Date(end).toJSON();
              
            }

           
            const result = await axios.get(`${API_URL}/myriota/api/get-data`,
            { params, headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            console.log(result)
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }


    public async getRecentAlerts(lat: number, lng: number, radius: number, hours: number){
        try{
           
            const endTime = new Date();
            const startTime = new Date(endTime);
            startTime.setHours(startTime.getHours() - hours);
            const result = await axios.get(`${API_URL}/metadata/api/alerts-in-area?lat=${lat}&lng=${lng}&radius=${radius}&startTime=${startTime.toJSON()}&endTime=${endTime.toJSON()}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            //console.log(`${API_URL}/api/alerts-in-area?lat=${lat}&lng=${lng}&radius=${radius}&startTime=${startTime.toJSON()}&endTime=${endTime.toJSON()}`)
 
            return result.data;
        }
        catch(error){
            console.log(error);
        }
        
    }

    // removed
    // public async getVideoAvailableDates(cameraId: number){
    //     try{
    //         const result = await axios.get(`${API_URL}/metadata/api/video-available-dates?cameraId=${cameraId}`,
    //         {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
    //         
    //         return result.data;
    //     }
    //     catch(error){
    //         console.log(error);
    //     }
      

    // }

    public async getLatestVideo(cameraId:number){
        //const newDate = new Date(date);

        try{
            const result = await axios.get(`${API_URL}/metadata/api/latest-video?cameraId=${cameraId}`,
            {  headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(e){
            console.log(e);
        }
        
    }

    public async getVideos(cameraId: number, start: Date | null, end: Date | null) {
     
        const params: { [key: string]: any } = { cameraId };
      
        if (start !== null) {
          params.start = new Date(start);
        }
      
        if (end !== null) {
          params.end = new Date(end);
          
        }
        try{
        
            const result = await axios.get(`${API_URL}/metadata/api/snail-videos`, { params, headers: {"Authorization" : `Bearer ${localStorage.getItem('jwt')}`} });
            
            return result.data;
        }
        catch(e){
            console.log(e);
        }
        return [];
        
        
    }


    /// for gamification:
     
    public async getUserInfo(){
       
         try{
            //const result = await axios.get(`${API_URL}/api/user-info?userId=${userId}`);
            // if(result.data['id'] != 0){
            //     
            //     return result.data;
            // }
            
            // temp solution:
            if(this.isLoggedIn()){
                return{
                    'userName': localStorage.getItem('userName')
                }
            }
            return{
                'userName': ''
            }
            
            
        }
        catch(error){
            console.log(error);
        }
        
     
    }

    public async reverseGeocoding(lon: number, lat: number){
        // TODO: hide this?
        const API_KEY = '0x40HhKWAYQwDtjN0BL6';
        const url = `https://api.maptiler.com/geocoding/${lon},${lat}.json?key=${API_KEY}`
        try{
            const result = await axios.get(url);
            
            return result.data;
        }
        catch(e){
            console.log(e);
        }
        return {};
        
    }

    // public async getQuestion( videoId: number){
        
    //     try{
    //         const result = await axios.get(`${API_URL}/api/new-question-for-user?userId=${this.getCurrentUserId()}&videoId=${videoId}`);
    //         
    //         return result.data;
    //     }
    //     catch(e){
    //         console.log(e);
    //     }
        

    // }

    // public async postQuestionResponse(
    //     questionId: number,
    //     videoId: number,
    //     answer: string
    // ){
    //     if(!this.isLoggedIn()){
    //         return null;
    //     }
    //     const response: any = {
    //         'userId': this.getCurrentUserId(), 
    //         'videoId': videoId,
    //         'questionId': questionId,
    //         'answer': answer
    //       };
      
    //     try {
    //         const result = await axios.post(`${API_URL}/api/user-question-response`, response);
    //         
    //         return result.data;
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //         throw error; 
    //     }

    // }

   
    // public async postSnailSurvey(
    //     videoId: number,
    //     snailExist: boolean | null,
    //     snailMoving: boolean | null,
    //     movementRating: number | null,
    //     quantityRating: number | null,
    //     snailCount: number | null,
    //     movingCount: number | null
    //   ){
    //     if(!this.isLoggedIn()){
    //         return null;
    //     }
    //     const survey: any = {
    //         'userId': this.getCurrentUserId(), 
    //         'videoId': videoId,
    //         'snailExist': snailExist,
    //         'snailMoving': snailMoving,
    //         'movementRating': movementRating,
    //         'quantityRating': quantityRating,
    //         'snailCount': snailCount,
    //         'movingCount': movingCount,
    //       };
      
    //     try {
    //         const result = await axios.post(`${API_URL}/api/new-snail-video-survey`, survey);
    //         
    //         return result.data;
    //     } catch (error) {
    //         console.error('An error occurred:', error);
    //         throw error; // Optionally re-throw the error for error handling further up the call stack
    //     }
    // }


    // public async getAnswerHistory() {
   
    //     const result = await axios.get(`${API_URL}/api/answer-history?userId=${this.getCurrentUserId()}`);
    //     
    //     return result.data;
    // }

    // public async getScoresHistogram(){
    //     const result=await axios.get(`${API_URL}/api/scores-histogram`);        
    //     return result.data;
    // }

    // public async getTopUsers(number: number) {
   
    //     const result = await axios.get(`${API_URL}/api/top-users?number=${number}`);
    //     
    //     return result.data;
    // }
    


  

}