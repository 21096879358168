<template>
    <div>
       
       <div  :id="plotId" :class="plotType=='rose'? 'rose-graph': 'graph'"></div>
       <b v-if="!data.windSpeeds || data.windSpeeds.length ==0" >Sorry. No data to display.</b>
       
    </div>
   </template>
   
   <script>
   import Plotly from 'plotly.js-dist';
   import Vue from 'vue'
   // constants for plot types
   const ROSE_PLOT = 'rose';
   const SCATTER_PLOT = 'scatter'
   // constants for handling wind directions
   const WIND_DIRECTIONS=[
        "N", "NNE", "NE", "ENE",
        "E", "ESE", "SE", "SSE",
        "S", "SSW", "SW", "WSW",
        "W", "WNW", "NW", "NNW"
    ];

    const DIRECTION_ANGLES =[
        0, 22.5, 45, 67.5,
        90, 112.5, 135, 157.5,
        180, 202.5, 225, 247.5,
        270, 292.5, 315, 337.5
    ];

    const DIRECTION_SYMBOLS= [
        "triangle-up", "", "triangle-ne", "",
        "triangle-right", "", "triangle-se", "",
        "triangle-down", "", "triangle-sw", "",
        "triangle-left", "", "triangle-nw", ""
    ];

    const ROSE_PLOT_UPDATE_FREQ = 1000 * 60 * 10; // ten minutes, in miliseconds
               
    export default{
        props:{
            data:{},
            plotId: String,
            hoveredX: Number,
            plotType: String, // 'rose', 'scatter'
           /*
            expected format of data
           {
               windSpeeds:[],
               windDirections:[]
               plotField: string,
               speedCategories:[
                {
                    max: number,
                    min: number,
                    name: string,
                    color: string
                }
               ],
               info:{
                   id: string,
                   title: string,
                   type: string,
                   unit: string
               }
           }
           */
           
       },
       watch:{
           //
           data:{
               handler: function(newVal, oldVal){
           
                   if(JSON.stringify(newVal) != JSON.stringify(oldVal)){            
                       if(this.plotType === ROSE_PLOT){
                            this.roseGraphEndtime = 0; // reset
                            this.createWindRoseGraph(this.data);
                        }
                        else{
                            this.createScatterGraph(this.data);
                        }
                   }
                      
               },
               
               deep: true,
               immediate: true,
               flush: 'post'
           },
           hoveredX:{
   
                handler: function(newVal, oldVal){
                    
                    if(this.plotType == SCATTER_PLOT){
                        Plotly.Fx.hover(this.plotRef, {
                            xval: newVal
                        });
                    }
                    var realTime = newVal + new Date().getTimezoneOffset() * 1000 * 60 * 1 // the hovered time was offset, see SnailVideosView.vue
                    if(this.plotType === ROSE_PLOT && Math.abs(realTime - this.roseGraphEndtime) > ROSE_PLOT_UPDATE_FREQ){
                        //console.log("redrawing rose graph")
                        this.roseGraphEndtime = realTime;
                        this.createWindRoseGraph(this.data);
                        
                     }
                        
                }   
           },
       },
       data(){
           return {
               plotRef: null,
               // used to map the direction numbers stored in db to directions
               // TODO: get this from backend incase this needs to be changed in future
              

                roseGraphEndtime: 0,
                
           }
       },
       methods:{
           async createWindRoseGraph(data){
                //console.log(data)
                if(data && document.getElementById(this.plotId) 
                    && data.windSpeeds && data.windDirections 
                    && data.windSpeeds.length === data.windDirections.length){
                    var plotData = [];
                    
                    // cannot guarantee data is sorted. find start end times when going through the array
                    var startTime = new Date().getTime();
                    var endTime = 0;
                    
                    var totalCount = 0;
                    var allCounts = {};
                    data.speedCategories.forEach((category)=>{
                        var directionsCount = {};

                        // unfortunately I have to go through the entire list for each category here
                        // can potentially be optimised
                        totalCount = 0;
                        for(var i=0; i<data.windSpeeds.length; i++){
                            var time = new Date(data.windSpeeds[i].timestamp  
                            + (data.windSpeeds[i].timestamp[data.windSpeeds[i].timestamp.length-1] === "Z"?
                            "" : "Z")).getTime();

                            if(time < startTime){
                                startTime = time;
                            }
                            if(time > endTime){
                                endTime = time;
                            }

                            if(this.roseGraphEndtime !== 0 && time > this.roseGraphEndtime ){
                                continue;
                            }
                            totalCount++;

                            var speed = data.windSpeeds[i][data.plotField];
                            if(speed >= category.min && speed< category.max){
                                var angle = WIND_DIRECTIONS[data.windDirections[i][data.plotField]]
                                if(!(angle in directionsCount)){
                                    directionsCount[angle] = 0
                                }
                                directionsCount[angle]++;
                               
                            }
                        }
                        

                        var percentages = [];
                        var directions = [];

                        // do this so that directions not in the dict will be included
                        for(var j=0; j<WIND_DIRECTIONS.length; j+=2){
                            directions.push(WIND_DIRECTIONS[j]);
                            percentages.push(directionsCount[WIND_DIRECTIONS[j]] * 100 / totalCount);
                        }

                        plotData.push({
                            // r: Object.values(directionsCount).map((count) => { return 100 * count/data.windSpeeds.length } ), // get percentage
                            // theta: Object.keys(directionsCount),
                            r: percentages,
                            theta: directions,
                            name: category.name,
                            marker: {color: category.color},
                          
                            type: "barpolar"
                        })
                    });
                    var layout = {
                        margin: {
                            //t: this.roseGraphEndtime==0? 100: 200,
                            t: 150
                        },
                        title: {
                            text: `Distribution of wind speeds and directions <br>${
                                '<span style="font-size:14">'+ this.formatToDayAndTime(new Date(startTime))+' - ' 
                                    + this.formatToDayAndTime(new Date(this.roseGraphEndtime == 0 ?endTime: this.roseGraphEndtime)) 
                                    + '</span>'}`,
                            
                        },
                        font: {size: 16},
                        legend: {font: {size: 16}},
                        polar: {
                            barmode: "stack",
                            bargap: 0,
                            radialaxis: {ticksuffix: "%", angle: 45, dtick: 10},
                            angularaxis: {direction: "clockwise"}
                        },
                        
                    }
                    //console.log(plotData)
                    var config = {responsive: true}
                    this.plotRef = await Plotly.newPlot(this.plotId, plotData, layout, config);
                   
                    this.plotRef.on('plotly_click', (data) => {
                        this.$emit('plotClicked', data)
                    });
                 
               }
           },
           async createScatterGraph(data){
            
            
                if(data && document.getElementById(this.plotId)
                    && data.windSpeeds && data.windDirections 
                    && data.windSpeeds.length === data.windDirections.length){
                    // data.values.sort(function(x, y){
                    //         return x.timestamp - y.timestamp;
                    //     })
                    
                    var values = [];
                    var times = [];
       
                    var markerSymbols = [];
                    var markerColors = [];

                    var hoverTexts = [];
                    
                    // markers too dense
                    for(var i=0; i<data.windSpeeds.length; i+= 20){
                        var speed = data.windSpeeds[i][data.plotField];
                        var directionIndex = data.windDirections[i][data.plotField]
                        values.push(speed)
                        // always parse as UTC (TODO: change this in backend)
                        times.push(this.formatDate(data.windSpeeds[i].timestamp  
                        + (data.windSpeeds[i].timestamp[data.windSpeeds[i].timestamp.length-1] === "Z"?
                        "" : "Z")))

                        markerSymbols.push(DIRECTION_SYMBOLS[directionIndex]);
                        // hoverTexts.push(`Speed: ${speed} m/s | Direction: ${WIND_DIRECTIONS[directionIndex]}` )
                        hoverTexts.push(WIND_DIRECTIONS[directionIndex]);
                        data.speedCategories.forEach((category)=>{
                            if(speed >= category.min && speed< category.max){
                                // should only be pushed once
                                markerColors.push(category.color);
                               
                            }
                        });
                    }
                    
                    var trace ={
                        mode: 'markers',
                        type: 'scatter',
                        x: times,
                        y: values,
                        // TODO: this may be inefficient, make sure sorted in backend instead?
                        transforms: [{
                            type: 'sort',
                            target: 'x',
                            order: 'ascending'
                        }],
                        text: hoverTexts,
                        marker: {
                            size: 12,
                     
                            color: markerColors,
                            symbol: markerSymbols
                        },
                        
                    }
                    var layout = {
                        title: data.info.title,
                        hovermode:'x',
                        margin: {
                            l: 50,
                            r: 50,
                            b: 70,
                            t: 50,
                            pad: 1
                        },
            
                        yaxis: {
                            title: {
                                text: data.info.type + ' (' + data.info.unit + ')',                   
                            }
                        },
                    
                        legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1,
                            //bgcolor: 'white'
                        },
                        //shapes: this.getBackGround(),
            
                        paper_bgcolor:'rgba(0,0,0,0)',
                        plot_bgcolor: 'rgba(0,0,0,0)'
                    };
                    
                    // makes the plot automatically resize
                    var config = {responsive: true}
              
                    this.plotRef = await Plotly.newPlot(this.plotId, [trace], layout, config);
                    Plotly.Fx.hover(this.plotRef, {
                        xval: this.hoveredX
                    });  

                    this.plotRef.on('plotly_click', (data) => {
                        this.$emit('plotClicked', data)
                    });
                  
                }
            
            },
            formatDate(timestampStr) {
                const date = new Date(timestampStr);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                const milliseconds = String(date.getMilliseconds()).padStart(6, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
            },

            formatToDayAndTime(date){
            
                return date.toLocaleString('en-US', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
            }
            
       },
       
   
       created(){
        //    console.log("Plot created")
        //    console.log(this.data);
       },
       mounted(){
            
            if(this.plotType === ROSE_PLOT){
                this.createWindRoseGraph(this.data);
            }
            else{
                this.createScatterGraph(this.data);
            }
            
           
       }
       
       
   }
   
   </script>
   
   <style>
   .rose-graph{
       height: 650px;
   }
   .graph{
        height: 300px;
    }
   
   
   </style>