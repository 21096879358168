<template>
 <div>
    
    <div :id="plotId" class="graph"></div>
    <b v-if="!data.values || data.values.length ==0" >Sorry. No data to display.</b>
    
 </div>
</template>

<script>
import Plotly from 'plotly.js-dist';
import Vue from 'vue'
//import VueDraggableResizable from 'vue-draggable-resizable'

//Vue.component('vue-draggable-resizable', VueDraggableResizable)
export default{
    props:{
        data:{},
        plotId: String,
        hoveredX: Number,
        /*
        {
            values:[],
            plotField: string,
            backgroundValues:[],
            backgroundField: string,
    
            info:{
                id: string,
                title: string,
                type: string,
                unit: string
            }
        }
        */
        
    },
    watch:{
        //
        data:{
            handler: function(newVal, oldVal){
                //console.log("data watcher called")
                // when the graph is dragged, handler is called without changing the value, I have no idea why
                // this is to make sure that the values has truely changed.
                if(JSON.stringify(newVal) != JSON.stringify(oldVal)){
                    //console.log("data changed!")
                    //console.log(JSON.stringify(newVal))
                    //console.log(JSON.stringify(oldVal))
                    
                    this.createPlot(newVal);
                }
                
                
            },
            
            deep: true,
            immediate: true,
            flush: 'post'
        },
        hoveredX:{

            handler: function(newVal, oldVal){
                //console.log("hovered!")
                Plotly.Fx.hover(this.plotRef, {
                    xval: newVal
                });   
            }   
        },
    },
    data(){
        return {
            plotRef: null,
        }
    },
    methods:{
        async createPlot(data){
           
            if(data && document.getElementById(this.plotId)){
            
                // data.values.sort(function(x, y){
                //         return x.timestamp - y.timestamp;
                //     })
                
                var values = [];
                var times = [];
              
                // var min = 9999
                // var max = 0
                if (data.values){
                    for(var i=0; i<data.values.length; i++){
                        // if(data.values[i][data.plotField] > max){
                        //     max = data.values[i][data.plotField];
                        // }
                        // if(data.values[i][data.plotField] < min){
                        //     min = data.values[i][data.plotField];
                        // }
                        // values.push(data.values[i][data.plotField] % 3600 / 10)
                        values.push(data.values[i][data.plotField])
                        // always parse as UTC (TODO: change this in backend)
                        times.push(this.formatDate(data.values[i].timestamp  
                        + (data.values[i].timestamp[data.values[i].timestamp.length-1] === "Z"?
                         "" : "Z")))
                    }

                    // console.log(max)
                    // console.log(min)
                }
                
                var trace ={
                    x: times,
                    y: values,
                    // TODO: this may be inefficient, make sure sorted in backend instead?
                    transforms: [{
                        type: 'sort',
                        target: 'x',
                        order: 'ascending'
                    }],
                    type: 'scatter'
                }
                var layout = {
                    title: data.info.title,
                    hovermode:'x',
                    margin: {
                        l: 50,
                        r: 50,
                        b: 70,
                        t: 50,
                        pad: 1
                    },
        
                    yaxis: {
                        title: {
                            text: data.info.type + ' (' + data.info.unit + ')',                   
                        }
                    },
                
                    legend: {
                        x: 1,
                        xanchor: 'right',
                        y: 1,
                        //bgcolor: 'white'
                    },
                    shapes: this.getBackGround(),
          
                    paper_bgcolor:'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)'
                };
            
                
                // console.log(trace);
                // console.log(new Date(this.hoveredX))
                // makes the plot automatically resize
                var config = {responsive: true}
                this.plotRef = await Plotly.newPlot(this.plotId, [trace], layout, config);
                Plotly.Fx.hover(this.plotRef, {
                    xval: this.hoveredX
                });  

                this.plotRef.on('plotly_click', (data) => {
                    this.$emit('plotClicked', data)
                });
                // this.plotRef.on('plotly_beforehover',function(){
                //     return false;
                // });
                // this.plotRef.on('plotly_hover', function(data){
                //     console.log(data)
                    
                // })
            }
        },
     
        getBackGround(){
            const backgroundColours = [];
            if(!this.data.backgroundValues){
                return [];
            }

        
            var prev = new Date();
            for(var i=0; i<this.data.backgroundValues.length; i++){
                // change color based on the level of snail movement
                var colour = 'green';
                if(this.data.backgroundValues[i][this.data.backgroundField] > 4){
                    colour = 'red';
                }
                else if(this.data.backgroundValues[i][this.data.backgroundField] > 1){
                    colour = 'yellow'
                }
                else if(this.data.backgroundValues[i][this.data.backgroundField] == 0){
                    colour = 'none'
                }
                backgroundColours.push({
                    layer: 'below',
                    fillcolor: colour,
                    opacity: 0.5,
                    type: 'rect',
                    // always parse as UTC (TODO: change this in backend)
                    x0: this.formatDate(this.data.backgroundValues[i].timestamp.replace(/\+11:00$/, "Z")),
                    x1: prev,
                    y0: 0,
                    y1: 300,
                    ysizemode: 'pixel',  
                    line: {
                        width: 0
                    } 
                });
                prev = this.formatDate(this.data.backgroundValues[i].timestamp.replace(/\+11:00$/, "Z"));
            }
            return backgroundColours;
        },
        formatDate(timestampStr) {
            const date = new Date(timestampStr);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            const milliseconds = String(date.getMilliseconds()).padStart(6, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
        },
    },
    

    created(){
        // console.log("Plot created")
        // console.log(this.data);
    },
    mounted(){
        this.createPlot(this.data);
    }
    
    
}

</script>

<style>
.graph{
    height: 300px;
}


</style>