<template>

    <div class="video-container">
        More snail videos from camera # {{ cameraId }}
        <VueDatePicker v-model="selectedDate" :disabled-dates="(date)=> !(checkDateAvailable(date))"></VueDatePicker>
        <hr style="width:90%;text-align:left;margin-left:5%">
        
        <video :key="videoKey" width="400" height="300" controls autoplay muted>
            <source :src="video? `${video.url}`: ''" type="video/ogg">
            Your browser does not support the video tag.
        </video>
    </div>
    
   
</template>



<script>
import SnailService from "../services/SnailService";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
const snailService = new SnailService();
export default{
    components: { VueDatePicker },
    props:{
        cameraId: Number,
    },
    data(){
        return{
            video: null,
            videoDates: [],
            selectedDate: null,
            videoKey:0,
        }
    },
    watch:{
        selectedDate:{
            // updates the video
            handler(newVal, oldVal){
                this.getVideo();
        

            },
        }
    },
    methods:{
        updateVideoElement(){
            this.videoKey++;
        },
        
        getVideoDates() {
            snailService.getVideoAvailableDates(this.cameraId)
                .then(res=> {
                    this.videoDates = res.map(dateTime => {
                        //const dateObj = new Date(dateTime);
                        //dateObj.setHours(0, 0, 0, 0); // Set time to midnight
                        return dateTime;
                    });
                } )
                .finally(() => this.selectedDate = this.videoDates[0]);
                
           
        },
        getVideo(){
            snailService.getVideo(this.cameraId, this.selectedDate)
                .then(res => this.video = res)
                .finally(()=> this.updateVideoElement());

        },

        // TODO: return Date values from api to reduce complexity in these:
        doesDateMatch(selectedDate, dateTimeStr) {
        // Compare the date parts of the selectedDate and dateTime
        console.log(selectedDate);
        console.log(dateTimeStr);
        var dateTime = new Date(dateTimeStr);
        console.log(dateTime);
        return (
            selectedDate.getFullYear() === dateTime.getFullYear() &&
            selectedDate.getMonth() === dateTime.getMonth() &&
            selectedDate.getDate() === dateTime.getDate()
        );
        },
        checkDateAvailable(selectedDate) {
        for (var dateTime of this.videoDates) {

            if (this.doesDateMatch(selectedDate, dateTime)) {
           
                return true;
            }
        }
        return false;
        },
  
    },
    created(){
        this.getVideoDates();
    }
}

</script>

<style>
    .video-container{
        width: 100%;
    }

</style>