import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SnailVideosView from '../views/SnailVideosView.vue'
import DashBoard from '../views/Dashboard.vue'
import LeaderBoard from '../views/Leaderboard.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/snail-videos/:cameraId',
    name: 'snail-videos',
    component: SnailVideosView
  },
  // redirects to camera with video?
  { path: '/snail-videos', redirect: '/snail-videos/0' },
  {
    path:'/dashboard',
 
    component: DashBoard
  },
  {
    path:'/leaderboard',
 
    component: LeaderBoard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
