<template>
    <div class="survey-container">
        <div v-if="!submitted && question">
            
            Answer this question for up to {{ question.maxScore }} points!
     
        
            <div class="question" >
                <label for="answerInput">
                    {{question.description}}
                </label>
                <VueSlider 
                    id="answerInput"
                    v-if="question.max"
                    v-model="answer" 
                    :min="question.min"
                    :max="question.max"
                   
                    :adsorb="true"
                    :process="true"
                    
                    :tooltip-formatter="question.max > 1? integerFormatter : percentageFormatter"
                />
                <b-form-select id="answerInput" v-else-if="question.choices" v-model="answer" :options="question.choices"></b-form-select>

                <input v-else class="form-control" id="answerInput" v-model="answer" >
                
            </div>
                
        </div>
        <div v-if="submitted">
            <h2>
                Thank you
            </h2>
            <b>
                You earned {{ resultScore }} points for your answer!
            </b>
        </div>
        
        
        <button @click="closeSurvey" class="close-button">Close</button>
        <button v-if="!submitted" @click="submitResponse" class="submit-button">Submit</button>
        
    </div>
 </template>
  
<script>
import SnailService from "../services/SnailService";
// import VueNumberInput from '@chenfengyuan/vue-number-input';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
  
const snailService = new SnailService();
export default {
    components: { 
        VueSlider,
    },
    props: {
        videoId: Number,
    },
    data() {
        return {
            submitted: false,
            question: null,
            answer: null,
            resultScore: null,

            integerFormatter: v => `${parseInt(v)}`,
            percentageFormatter: v => `${v*100}%`,
            zeroToTenFormatter: v => `${v*10}`,

        };
    },
    methods: {
      
        getQuestion(){
            snailService.getQuestion(this.videoId)
                .then(res => this.question = res)
                .finally(() => {
                    if(!this.question){
                        this.closeSurvey();
                    }
                });
        },
       
        submitResponse(){
            snailService.postQuestionResponse(this.question.id, this.videoId, String(this.answer))
                .then(res => this.resultScore = res)
                .finally(this.submitted = true);
        },
        closeSurvey(){
            this.$emit('close');
        }
    },
    mounted(){
       
        this.getQuestion();
    }
};
</script>

<style scoped>
.survey-container {
    /* max-width: 400px; */
    width: 90%;
    
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.question {
    margin: 20px;
    margin-bottom: 10px;
    /* display: flex;
    align-items: center; */
}

label {
    font-weight: bold;
    margin-right: 10px;
}



.submit-button {
    background-color: #03b258;
    color: white;
    margin: 10px;
    padding: 10px 20px;
    border: 1px;
    border-radius: 5px;
    cursor: pointer;
}

.close-button {
    /* background-color: #e26506; */
    color: rgb(255, 0, 0);
    margin: 10px;
    padding: 10px 20px;
    border: 1px;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #027e21;
}

.close-button:hover {
    background-color: #cbcbcb;
}
.yes-no-button {
    background-color: #007bff;
    width: 60%;
    color: white;
    margin: 5px;
        padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.yes-no-button:hover {
    background-color: #0056b3;
}

.yes-no-button.active {
    
    background-color: #003d7e;
    color: white;
}

</style>
