<template>
    <div class="map-wrap">
        <div class="map" ref="mapContainer"></div>
        

        <a href="https://www.flaticon.com/free-icons/sensor" title="sensor icons">Sensor icons created by Freepik - Flaticon</a>
    </div>
    

</template>
  
<script>

import { shallowRef, markRaw, onMounted, onUnmounted} from 'vue';
import { Map, NavigationControl, Marker, Popup, LngLatBounds, LngLat, maplibregl  } from 'maplibre-gl';
import { GeocodingControl } from "@maptiler/geocoding-control/maplibregl";


//import video from '../assets/videos/White.ogv';

import SnailService from "../services/SnailService";

const snailService = new SnailService();
export default{
    props:{
        cameras: Array,
        devices: Array,
        alerts: {},
        center:{},
        initialState:
        {     
            default(){ // coordinates of Adelaide as default
                return {
                    lng:138.6007, 
                    lat: -34.9285, 
                    zoom: 4,
               
                }         
            }      
        },

    },
    data(){
        return{

            currentMarkers: [],
            
        }
    },


    watch:{
       devices:{
            // updates the devices displayed
            handler(newVal, oldVal){
                // remove the previous sensors
                // if (oldVal){
                //     for(var i=0; i<oldVal.length; i++){
                //         var marker = this.currentMarkers.pop();
                //         marker.remove();
                //     }
                // }                
                //this.addMarkers(newVal, 'sensor')
            },
            deep: true,
            immediate: true
        },
    
        center:{
            // move the map to the new center
            handler(newVal, oldVal){
                this.centerTo(newVal)
            },
            deep: true,
            immediate: true
        }
        
    },
    methods:{
        
        centerTo(coordinates){
            if (this.map){
               
                this.map.flyTo({
                    center:[coordinates.lng, coordinates.lat],
                    zoom: 10,
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                });
                
            }
            
            
        },
        async addMarkers(markers, type){
            if(markers == null){
                return;
            }

            const IMG_URL = {
                "camera": require("../assets/camera-icon.png"),
                "sensor": require("../assets/sensor-icon.png"),
      
            };

            
            for(const marker of markers){
                // set the styles of the marker
                var el = document.createElement('div');
                el.className = 'marker';
                
                el.style.backgroundImage = `url(${IMG_URL[type]})`;
                el.style.backgroundSize = 'contain';
                el.style.width = "30px";
                el.style.height = "30px";
                 
                
                // create the popup
                var popupElement = document.createElement(type +"_popup_" + marker.id);
                var button = document.createElement('button');
                button.innerHTML = 'View Data'
                
                
                if(type == 'camera'){
                    el.addEventListener('click', () => {
                        this.$emit('cameraClicked', marker)
                    });
                    // var videoDate = await this.getVideoDate(marker.cameraId);
                    var video= await snailService.getLatestVideo(marker.cameraId);
                    var dateOptions = {  year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                    var popupContent = `<h3>${marker.camera.description}</h3><br>`;


                    // for(var alert of this.alerts[marker.id]){
                    //     var font_color="orange"
                    //     if(alert.level > 0.6){
                    //         font_color = 'red'
                    //     }
                    //     else if (alert.level <=0.3){
                    //         font_color = 'green'
                    //     }
                    //     var timestamp = new Date(alert.timestamp)
                    //     popupContent += `${timestamp.toLocaleDateString("en-AU", dateOptions)}:<br> 
                    //         <b><font color=${font_color}> level: ${alert.level}</font> | confidence: ${alert.confidence}</b><br>`;
                        
                    //     // only display the most recent one for now    
                    //     break; // remove this to display all
                    // }              
                    
                    if(video){
                        popupContent += `<video width="100%" height="auto" style="border-radius:20px"  controls autoplay muted>
                                        
                                        
                                        <source src=${video.url} type="video/mp4">
                                        
                                        Your browser does not support the video tag.
                                    </video>`;           
                    }
                    // add a button to navigate to all videos            
                    var videoButton = document.createElement('button');
                    videoButton.className = 'btn btn-success';
                    videoButton.innerHTML = 'View All Videos'
                    videoButton.onclick = ()=>{
                        this.$router.push(`/snail-videos/${marker.cameraId}`);
                        //popupElement.appendChild(videoButton);
                    };
                    
                    marker.type = 'camera'
                    button.className = 'btn btn-success';
                    button.style.margin= '5px';
                    button.onclick = ()=>{
                        this.$emit('viewGraph', marker);
                    }

                    
                  
                }
                else if(type == 'sensor'){
                    popupContent = `<b>#${marker.device.id}   ${marker.device.description}  </b><br>`;
                    // for(var sensor of marker.device.sensors){
                    //     popupContent += `${sensor.type.description} sensor #${sensor.id}: '${sensor.description}'<br>`
                    // }
                    marker.type = 'sensor'
                    button.className = 'btn btn-success';
                    button.style.margin= '5px';
                    button.onclick = ()=>{
                        marker.type = 'sensor'
                        this.$emit('viewGraph', marker);
                    }
                    
                }
            
                popupElement.innerHTML = popupContent;
                popupElement.appendChild(button);
                if(videoButton){
                    popupElement.appendChild(videoButton);
                }
                
                
                var popup = new Popup({ offset: 25, closeOnClick: false}).setDOMContent(popupElement);          
                var newMarker = new Marker(el)
                    .setLngLat([marker.longitude, marker.latitude])
                    .setPopup(popup)             
                    .addTo(this.map);
            
                this.currentMarkers.push(newMarker);
            }
        },
        // get most recent video date
        async getVideoDate(cameraId) {
            try {
                const dates = await snailService.getVideoAvailableDates(cameraId);
                return dates ? dates[0] : null;
            } catch (error) {
                // Handle any errors that might occur during the request
                console.error(error);
                return null;
            }
        },


        
    },

    
    setup(props,{emit}){
       
        // TODO: hide this?
        const API_KEY = '0x40HhKWAYQwDtjN0BL6';
    
        const mapContainer = shallowRef(null);
        const map = shallowRef(null);
        //var currentMarkers=[];  // list to keep track of the displayed markers, so we can update them when needed  
    
        
        onMounted(() => {
            // initialise the map
            map.value = markRaw(new Map({
            container: mapContainer.value,
            style: `https://api.maptiler.com/maps/outdoor-v2/style.json?key=${API_KEY}`,
            center: [props.initialState.lng, props.initialState.lat],
            zoom: props.initialState.zoom,
            maxZoom: 14
            }));

            map.value.addControl(new NavigationControl(), 'top-right');
            const apiKey = API_KEY
            // search bar, not needed?
            //const gc = new GeocodingControl({ apiKey, maplibregl });
            //map.value.addControl(gc);
        }),


        onUnmounted(() => {
            map.value?.remove();
        })
      
        return {
            map, mapContainer
        };
    },
    
    mounted(){
        this.map.on('load', () =>{
            //this.resetView();
            this.addMarkers(this.cameras, 'camera')
            this.addMarkers(this.devices, 'sensor')
            
            
        });
      
        this.map.on('zoom',()=>{
            this.viewChanged = true;
            //console.log(this.viewChanged)
        })
        this.map.on('move',()=>{
            this.viewChanged = true;
            //console.log(this.viewChanged)
        })
        
        
   
    }
  };
  
  </script>
  
  
  <style scoped>
  @import '~maplibre-gl/dist/maplibre-gl.css';
  /* does not work for some reason */
  /* @import "@maptiler/geocoding-control/dist/style.css";  */
  .map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 110px); /* calculate height of the screen minus the heading */
    
  }
  
  .map {
    position: absolute;
    width: 100%;
    height: 100%;
  
  }
  
  .watermark {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 999;
  }

  /* styles copied from https://docs.maptiler.com/maplibre-gl-js/example/zoomto-linestring/*/
  .btn-control {
        font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
        background-color: #3386c0;
        color: #fff;
        position: absolute;
        top: 20px;
        left: 50%;
        z-index: 1;
        border: none;
        width: 200px;
        margin-left: -100px;
        display: block;
        cursor: pointer;
        padding: 10px 20px;
        border-radius: 3px;
    }

    .btn-control:hover {
        background-color: #4ea0da;
    }
    .btn-control:disabled,
    button[disabled] {
        cursor: not-allowed;
        pointer-events: none;
        opacity:0.3;
    }
  </style>