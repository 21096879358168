import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
//import BootstrapVueNext from 'bootstrap-vue-next'
import BootstrapVueNext from 'bootstrap-vue-next'
import IconsPlugin from "bootstrap-vue-next"
import BootstrapVueIcons from "bootstrap-vue-next";
import SidebarPlugin from "bootstrap-vue-next";


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

// Create your Vue app instance
const app = createApp(App);

// Use the router
app.use(router);

app.use(BootstrapVueNext);
app.use(IconsPlugin);
app.use(BootstrapVueIcons);
app.use(SidebarPlugin);

// Mount the app to the DOM
app.mount('#app');
